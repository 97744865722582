<template>
  <div class="merchant-list-container">
    <div class="fixed-subcategories pb-3">
      <transition name="fade">
        <div v-if="loading" class="flex">
          <Skeleton
            v-for="i in 2"
            :key="i"
            height="2rem"
            width="12%"
            class="m-2"
          />
        </div>
        <div
          v-else
          class="subcategories md:grid overflow-x"
          :class="'md:grid-cols-' + subCategories.length"
        >
          <div
            v-for="(category, index) in subCategories"
            :key="index"
            class="option text-sm md:text-base cursor-pointer text-dark border p-2 rounded-lg mt-2 mr-2"
            :class="[
              selectedSubCategories.includes(category)
                ? 'selected'
                : 'not-selected',
            ]"
            @click="selectSubCategory(category)"
          >
            {{ category.name }}
          </div>
        </div>
      </transition>
    </div>

    <div class="content scrollbar">
      <EmptyState
        class="mt-8"
        :description="description"
        v-if="!featuredMerchants.length"
      />
      <div v-else class="mb-3">
        <div class="grid md:grid-cols-2 gap-1">
          <div v-for="(merchant, index) in featuredMerchants" :key="index">
            <div
              class="flex items-center md:py-6 md:px-3 py-4 px-2 justify-between cursor-pointer overflow-auto"
              @click="$emit('confirmation', merchant)"
            >
              <div class="flex items-center overflow-auto">
                <img
                  class="mr-2 w-8 max-h-8"
                  :src="getImage(merchant.logoId)"
                  :alt="merchant.name"
                  @error="handleErrorImage"
                />
                <p class="font-semibold text-sm break-words">
                  {{ merchant.name | truncate(40) }}
                </p>
              </div>
            </div>
          </div>
          <Pagination
            :handleMore="handleMore"
            :loading="paginateLoading"
            @paginate="handlePagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getImage from "@/mixins/getImage";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [getImage],
  components: {
    EmptyState: () => import("@/UI/EmptyState"),
    Pagination: () => import("@/UI/Pagination"),
    // Spinner: () => import("@/UI/Spinner"),
  },
  data() {
    return {
      selectedSubCategories: [],
      params: {
        page: 0,
        pageSize: 30,
        from: "",
        to: "",
        featuredCategoryId: "",
      },
      loading: false,
      paginateLoading: false,
      handleMore: false,
      items: [],
    };
  },
  props: {
    featuredMerchants: {
      type: Array,
      default: () => [],
      required: true,
    },
    currentCategory: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    ...mapState({
      subCategories: (state) => state.dashboard.subCategoryList,
    }),
    description() {
      const title = this.items.length > 0 ? "subcategory" : "category";
      return `There are currently no merchants in this ${title}, Please check back :)`;
    },
  },
  mounted() {
    this.params.featuredCategoryId = this.currentCategory.id;
    this.getSubCategoryList();
  },

  beforeDestroy() {
    if (this.items.length) {
      this.incrementSubCategoryCount({ items: this.items });
    }
    this.clearSubCategories();
  },

  methods: {
    ...mapActions("dashboard", [
      "getMerchantSubCategories",
      "incrementSubCategoryCount",
      "clearSubCategories"
    ]),
    handleErrorImage(e) {
      return (e.target.src =
        "https://res.cloudinary.com/zillaafrica/image/upload/v1631281213/customer/Group_12946_yr3hik.svg");
    },
    selectSubCategory(category) {
      if (this.selectedSubCategories.includes(category)) {
        this.selectedSubCategories = this.selectedSubCategories.filter(
          (item) => item !== category
        );
      } else {
        this.handleCategoryCount(category.id);
        this.selectedSubCategories.push(category);
      }
      this.$emit("selectSubCategories", this.selectedSubCategories);
    },
    getSubCategoryList(type) {
      this.getMerchantSubCategories({ type: type, params: this.params })
        .then((res) => {
          this.$nextTick()

          // if (res.data.length === this.params.pageSize) {
          //   this.handleMore = true;
          // } else {
          //   this.handleMore = false;
          // }
          // type === "paginate"
          //   ? (this.paginateLoading = false)
          //   : (this.loading = false);
        })
        .catch(() => {
          // type === "paginate"
          //   ? (this.paginateLoading = false)
          //   : (this.loading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getSubCategoryList("paginate");
    },

    handleCategoryCount(id) {
      const found = this.items.find((el) => el.subCategoryId === id);
      if (found) {
        this.items.forEach((item) => {
          if (item.subCategoryId === id) {
            item.count++;
          }
        });
      } else {
        this.items.push({ count: 1, subCategoryId: id });
      }
    },
  },
};
</script>
<style scoped>
.merchant-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.not-selected {
  background-color: transparent;
  border-color: #ededf0;
}

.selected {
  background-color: #e0dfe3;
  border-color: #c2c0c7;
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
}
.subcategories .option:last-child {
  margin-right: 0px !important;
}

.subcategories::-webkit-scrollbar {
  display: none;
}

.content {
  flex: 1;
  overflow: auto;
}

/* .fixed-subcategories {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  background-color: #fff;
} */

@media (max-width: 768px) {
  .subcategories {
    position: relative;
    flex-wrap: nowrap;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch;
    box-shadow: inset -17px 0px 9px -7px rgb(244, 244, 244);
    /* box-shadow: inset -17px 0px 9px -7px rgba(41, 41, 41, 0.06); */
  }

  .subcategories .option {
    flex: 0 0 auto;
    margin-right: 10px;
  }
}
</style>

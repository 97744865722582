var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "merchant-list-container" }, [
    _c(
      "div",
      { staticClass: "fixed-subcategories pb-3" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "flex" },
                _vm._l(2, function (i) {
                  return _c("Skeleton", {
                    key: i,
                    staticClass: "m-2",
                    attrs: { height: "2rem", width: "12%" },
                  })
                }),
                1
              )
            : _c(
                "div",
                {
                  staticClass: "subcategories md:grid overflow-x",
                  class: "md:grid-cols-" + _vm.subCategories.length,
                },
                _vm._l(_vm.subCategories, function (category, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "option text-sm md:text-base cursor-pointer text-dark border p-2 rounded-lg mt-2 mr-2",
                      class: [
                        _vm.selectedSubCategories.includes(category)
                          ? "selected"
                          : "not-selected",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.selectSubCategory(category)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(category.name) + " ")]
                  )
                }),
                0
              ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content scrollbar" },
      [
        !_vm.featuredMerchants.length
          ? _c("EmptyState", {
              staticClass: "mt-8",
              attrs: { description: _vm.description },
            })
          : _c("div", { staticClass: "mb-3" }, [
              _c(
                "div",
                { staticClass: "grid md:grid-cols-2 gap-1" },
                [
                  _vm._l(_vm.featuredMerchants, function (merchant, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center md:py-6 md:px-3 py-4 px-2 justify-between cursor-pointer overflow-auto",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("confirmation", merchant)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-center overflow-auto" },
                            [
                              _c("img", {
                                staticClass: "mr-2 w-8 max-h-8",
                                attrs: {
                                  src: _vm.getImage(merchant.logoId),
                                  alt: merchant.name,
                                },
                                on: { error: _vm.handleErrorImage },
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-semibold text-sm break-words",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("truncate")(merchant.name, 40)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  _c("Pagination", {
                    attrs: {
                      handleMore: _vm.handleMore,
                      loading: _vm.paginateLoading,
                    },
                    on: { paginate: _vm.handlePagination },
                  }),
                ],
                2
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }